import React, { useContext } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"

import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

import styles from "./utils/elements.module.scss"
import { AppContext } from "../../context/AppContext"
import { consentAndAuth } from "../../context/AppReducer"
import { validationSchema } from "./utils/consentAndAuthSchema"

const ConsentAndAuthorization = ({ children, handleOnSubmit, module }) => {
  const data = useStaticQuery(graphql`
    {
      termsAndConditions: allAirtableTermsAndConditions(
        sort: { order: ASC, fields: data___Order }
      ) {
        nodes {
          data {
            Section
            Body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      privacyPolicy: allAirtablePrivacyPolicy(
        sort: { order: ASC, fields: data___Order }
      ) {
        nodes {
          data {
            Body {
              childMarkdownRemark {
                html
              }
            }
            Answer {
              childMarkdownRemark {
                html
              }
            }
            Question
          }
        }
      }
    }
  `)

  const { state, dispatch } = useContext(AppContext)

  const TERMS_AND_CONDITIONS_BLURB = module === "doctor" ? "I agree to the terms and conditions above." : "By submitting my enrollment, I acknowledge that I agree to the terms and conditions above."
  const TERMS_AND_CONDITIONS = data.termsAndConditions.nodes
  const PRIVACY_POLICY = data.privacyPolicy.nodes

  let index = module === "doctor" ? 0 : 1
  let termsAndConditions = TERMS_AND_CONDITIONS[index]

  return (
    <Section title="Consent and Authorization" isRequired>
      <div className="mx-2 mx-0-mobile">
        <Formik
          initialValues={{ agreeToConsent: state.consentAndAuth }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <article className="message">
                <div
                  className={classNames("message-body", styles["collapsible"])}
                >
                  <section className="content">
                    <div>
                      <b>{termsAndConditions.data.Section}</b>
                      <div
                        className="my-1"
                        dangerouslySetInnerHTML={{
                          __html:
                            termsAndConditions.data.Body.childMarkdownRemark
                              .html,
                        }}
                      ></div>
                    </div>
                    <div>
                      <b>Privacy Policy</b>
                      <div
                        className="my-1"
                        dangerouslySetInnerHTML={{
                          __html: PRIVACY_POLICY[0].data.Body.childMarkdownRemark.html.replace(
                            /\n/g,
                            "<br />"
                          ),
                        }}
                      ></div>
                      <div className="content">
                        <ul>
                          {PRIVACY_POLICY.map(faq => {
                            return (
                              <li>
                                <b>{faq.data.Question}</b>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      faq.data.Answer.childMarkdownRemark.html,
                                  }}
                                ></div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={[TERMS_AND_CONDITIONS_BLURB]}
                onChange={event =>
                  dispatch({
                    type: consentAndAuth.SAVE_CONSENT_AND_AUTH,
                    payload: event.target.checked
                      ? [TERMS_AND_CONDITIONS_BLURB]
                      : [],
                  })
                }
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
