import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"

const ActionButtons = ({ submit, next, back }) => (
  <div className={classNames("buttons is-centered mt-4")}>
    {back && (
      <Button
        onClick={() => {
          if (back.callback) {
            back.callback()
            return
          }
          navigate(back.link)
        }}
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          back.className
        )}
        size="medium"
      >
        {back.label}
      </Button>
    )}

    {next && (
      <Button
        onClick={() => navigate(next.link)}
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          next.className
        )}
        size="medium"
        color="primary"
        isDisabled={next.disabled}
      >
        {next.label}
      </Button>
    )}

    {submit && (
      <Button
        className={classNames(
          "px-2 px-2-mobile mr-1 mr-1-mobile",
          submit.className
        )}
        size="medium"
        color="primary"
        type="submit"
        isLoading={submit.loading}
        isDisabled={submit.disabled}
        onClick={submit?.onClick}
      >
        {submit.label}
      </Button>
    )}
  </div>
)

export default ActionButtons
