import React from "react"

const Brand = ({ type = "complete", ...props }) => {
  switch (type) {
    case "company":
      return <span className={props.className}>Boehringer Ingelheim</span>
    case "brand":
      return <span className={props.className}>HOPE</span>
    case "jardiance":
      return <span className={props.className}>Empagliflozin (Jardiance<sup>®</sup>)</span>
    case "respimat":
      return <span className={props.className}>Tiotropium Bromide + Olodaterol HCl (Spiolto<sup>®</sup> Respimat<sup>®</sup>)</span>
    default:
      return <span className={props.className}>Complete</span>
  }
}

const ProgramName = ({ ...props }) => {
  return (
    <span className={props.className}>
      <Brand type="brand" /> Program
    </span>
  )
}

export { Brand, ProgramName }
