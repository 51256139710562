import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({
  title,
  children,
  addOns,
  className,
  id,
  isRequired,
  subtitle,
}) => (
  <section id={id || ""}>
    {title && (
      <div className={classNames(styles["section"])}>
        <section className={classNames(styles["section__title"])}>
          {addOns?.left}
          <h4
            className={classNames("has-text-primary mt-0", {
              "mb-0": !!subtitle,
            })}
          >
            {title}{" "}
            {!isRequired && (
              <span className="is-italic has-text-weight-normal has-text-grey">
                (Optional)
              </span>
            )}
          </h4>
          {addOns?.right}
        </section>
        {subtitle && (
          <p className="is-italic has-text-grey is-size-6">{subtitle}</p>
        )}
      </div>
    )}
    <div className={className || "mt-1"}>{children}</div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
